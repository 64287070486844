import { NgModule } from '@angular/core';
import {
  AuthGuard,
  customClaims,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { map, pipe } from 'rxjs';

const redirectUnauthorizedToSignin = () => {
  return redirectUnauthorizedTo(['sign-in']);
};

const redirectToDashboard = (next: any) =>
  pipe(
    customClaims,
    map((claims: any) => {
      if (claims.is_staff) {
        return ['/staff/dashboard'];
      }
      // if (claims.is_member) {
      //   return ['/members/dashboard'];
      // }
      if (claims.user_id) {
        return ['/members/dashboard'];
      }
      return true;
    })
  );

const staffOnly = () =>
  pipe(
    customClaims,
    map((claims: any) => {
      return claims.is_staff;
    }),
    redirectUnauthorizedToSignin()
  );

const membersOnly = () =>
  pipe(
    customClaims,
    map((claims: any) => {
      return claims.user_id;
    }),
    redirectUnauthorizedToSignin()
  );

const routes: Routes = [
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./sign-in/sign-in.module').then((m) => m.SignInModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectToDashboard },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'finish-sign-in',
    loadChildren: () =>
      import('./sign-in-finish/sign-in-finish.module').then(
        (m) => m.SignInFinishModule
      ),
  },
  {
    path: 'status',
    loadChildren: () =>
      import('./status-pages/status-pages.module').then(
        (m) => m.StatusPagesModule
      ),
  },
  {
    path: 'staff',
    loadChildren: () =>
      import('./staff/staff.module').then((m) => m.StaffModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: staffOnly },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'members',
    loadChildren: () =>
      import('./members/members.module').then((m) => m.MembersModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: membersOnly },
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
