import { NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import * as Sentry from '@sentry/angular';
import { filter, map } from 'rxjs';
import { environment } from '../environments/environment';
import { AppToastComponent } from './@parts/app-toast/app-toast.component';

@Component({
  selector: 'chief-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [LoadingBarModule, RouterOutlet, NgIf, AppToastComponent],
})
export class AppComponent implements OnInit, OnDestroy {
  private swUpdate = inject(SwUpdate);
  private titleService = inject(Title);
  private auth = inject(Auth);
  title = 'chief';
  isNotProduction = !environment.production;
  unsubAuth = this.auth.onAuthStateChanged((user) => {
    if (user && user.email) {
      Sentry.setUser({ email: user.email });
    } else {
      Sentry.setUser(null);
    }
  });

  updatesAvailable = this.swUpdate.versionUpdates.pipe(
    filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
    map((evt) => ({
      type: 'UPDATE_AVAILABLE',
      current: evt.currentVersion,
      available: evt.latestVersion,
    }))
  );

  constructor() {
    const tooltipConfig = inject(NgbTooltipConfig);

    tooltipConfig.container = 'body';
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.updatesAvailable.subscribe((update) => {
        if (confirm('An update to Chief is available. Want to update?')) {
          window.location.reload();
        }
      });
    }
    this.titleService.setTitle(
      `${this.isNotProduction ? '!DEV! ' : ''} OSFA's Chief DB`
    );
  }

  ngOnDestroy(): void {
    this.unsubAuth();
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
