import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, TemplateRef, inject } from '@angular/core';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../@services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './app-toast.component.html',
  styleUrls: ['./app-toast.component.scss'],
  standalone: true,
  imports: [NgFor, NgbToast, NgIf, NgTemplateOutlet],
})
export class AppToastComponent {
  public toastService = inject(ToastService);

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
