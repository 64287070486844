<ngx-loading-bar></ngx-loading-bar>
<router-outlet></router-outlet>
<ng-container *ngIf="isNotProduction">
  <!-- DEVELOPMENT MARKER -->
  <div style="height:8px;background-color:rgb(255, 140, 0);z-index:100000;position:fixed;top:0;left:0;right:0;"></div>
  <!-- <div style="width:8px;background-color:rgb(255, 140, 0);z-index:100000;position:fixed;top:0;left:0;bottom:0;"></div>
  <div style="width:8px;background-color:rgb(255, 140, 0);z-index:100000;position:fixed;top:0;bottom:0;right:0;"></div> -->
  <div style="height:8px;background-color:rgb(255, 140, 0);z-index:100000;position:fixed;bottom:0;left:0;right:0;">
  </div>
</ng-container>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>
